<template>
  <!-- eslint-disable -->
  <div class="terms-and-use">
    <div class="scroll">
      <div class="header">
        <img src="~@/assets/images/sally-logo-full.svg" />
      </div>
      <div class="title">이용약관</div>
      <div class="detail-container">
        <div class="detail">
          <span class="title2">제 1 장 총칙</span>
          <span class="title3">제 1 조 (목적)</span>
          <pre class="contents">
 이 약관은 삼성에스디에스 주식회사(이하 "회사"라 합니다)가 제공하는 sally를 통해 제공되는 진단 및 설문조사 진행을 위한 설문 제작/배포/결과조회 서비스를(이하 ‘sally 서비스’라 한다)를 이용하고자 하는 사용자(설문을 사용하여 진단 및 취합 업무를 하는 컨설턴트, 코치)가 ‘sally 서비스’를 이용함에 있어 발생하는 ‘sally 서비스’를 제공하는 ‘회사’와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.          </pre>
          <span class="title3">제 2 조 (약관의 효력 및 변경)</span>
          <pre class="contents">
 1. 이용자가 ‘sally 서비스’를 이용하려면 약관에 동의해야 합니다. 일반적으로 약관에 대하여 이용자는 ‘sally 서비스’가 제공하는 사이트 가입 화면에 구현된 "동의" 버튼을 클릭하여 동의할 수 있습니다.
 2. 이 약관은 이용자(삼성SDS 임직원)가 ‘sally 서비스’를 이용함으로써 이용자에게 효력이 발생됩니다. 이 경우 이용자는‘sally 서비스’의 이용과 동시에 약관이 적용하며 ‘sally 서비스’가 약관에 따라 이용자로 대우한다는 사실을 인지합니다.
 3. ‘sally 서비스’는 사정상 사유가 발생될 경우 사전 고지 없이 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 ‘sally 서비스’홈페이지를 통하여 7일 이전에 공지합니다.
 4. 이용자가 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부 의사를 명시적으로 표시하지 않거나 약관 변경 이후에 서비스를 이용할 경우 변경된 약관에 동의한 것으로 봅니다. 이용자가 변경된 약관에 동의하지 않는 경우, ‘sally 서비스’에서 제공하는 서비스 이용이 제한되거나 정지될 수 있습니다.
 5. 이용자가 ‘sally 서비스’ 회원에 가입/탈퇴하는 경우, ‘sally 서비스’ 이용약관에 따릅니다.
          </pre>
          <span class="title3">제 3 조 (약관 외 준칙)</span>
          <pre class="contents">
 본 약관에 명시되지 아니한 사항에 대해서는 전기통신 기본법, 전기통신사업법 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정에 따릅니다.
 1. ‘회사’ 및 ‘sally 서비스’와 이용자간 제기된 소송에는 대한민국 법을 준거법으로 적용합니다.
 2. 해외 이용자의 경우 본 서비스를 이용함으로써 국내법 적용에 동의하는 것으로 간주합니다.
          </pre>
          <span class="title2">제 2 장 서비스 이용 계약</span>
          <span class="title3">제 4 조 (대리행위의 부인)</span>
          <pre class="contents">
 ‘회사’는 효율적인 서비스를 위한 시스템 운영 및 관리 책임만을 부담하며, 재화 또는 용역의 거래와 관련하여 대리하지 아니하고, 이용자 사이에 성립된 거래 및 사용자가 제공하고 등록한 정보에 대해서는 해당 이용자가 그에 대한 직접적인 책임을 부담하여야 합니다.
          </pre>
          <span class="title3">제 5 조 (보증의 부인)</span>
          <pre class="contents">
 ‘sally 서비스’는 회사가 제공하는 시스템을 통하여 이루어지는 이용자들 간의 데이터와 관련하여 이용자가 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성, 적법성 또는 기밀성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 이용자가 전적으로 부담합니다.
          </pre>
          <span class="title3">제 6 조 (이용 신청)</span>
          <pre class="contents">
 회원으로 가입하여 서비스를 이용하기를 희망하는 자는 ‘sally 서비스’가 정한 소정의 양식에 따라 요청하는 개인 인적사항을 제공하여 이용신청을 합니다.
          </pre>
          <span class="title3">제 7 조 (이용 신청의 승락)</span>
          <pre class="contents">
 1. ‘sally 서비스’는 제 6 조에 따른 이용 신청에 대하여 특별한 사정이 없는 한 접수 순서대로 이용 신청을 승락합니다.
 2. ‘sally 서비스’는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
 ① 서비스 관련 서버 등 IT 인프라의 여유가 없을 경우
 ② 기타 ‘sally 서비스’의 사정상 필요하다고 인정되는 경우
 3. ‘sally 서비스’는 다음 각 호에 해당하는 사항을 인지하는 경우 동 이용계약 신청을 승낙하지 아니할 수 있습니다.
 ① 다른 사람의 명의나 e-mail을 사용하여 신청한 경우
 ② 이용 신청 시 필요 사항을 허위로 기재하여 신청한 경우
 ③ 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우
 ④ 이미 가입된 사용자의 ID(e-mail) 항목이 동일한 경우
 ⑤ 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
 ⑥ 기타 회사가 정한 이용 신청 요건이 미비된 경우
 4. 위 제 2항 또는 3항에 의하여 이용 신청의 승낙을 유보하거나 승낙하지 아니하는 경우, 회사는 이를 이용 신청자에게 알려야 합니다. 다만, 회사의 귀책 사유 없이 이용 신청자에게 통지할 수 없는 경우는 예외로 합니다.
          </pre>
          <span class="title3">제 8 조 (서비스 이용 범위)</span>
          <pre class="contents">
 1. 이용자는 회사가 ‘sally 서비스’를 통해 제공하는 ‘sally 서비스’를 사용 할 수 있으며, ‘sally 서비스’가 제공하는 서비스는 아래와 같습니다.
 ① 설문 작성
 ② 설문 배포
 ③ 설문 결과보기
 ④ 리포트 생성
 2. 회사는 ‘sally 서비스’ 관련된 제반 권리 및 약관에서 언급되지 않은 모든 권리를 소유합니다. 이용자는 ‘sally 서비스’ 관련하여 어떠한 권리도 행사할 수 없음을 확인합니다.
          </pre>
          <span class="title3">제 9 조 (서비스 제공 및 중지)</span>
          <pre class="contents">
 1. ‘sally 서비스’는 이용자에게 서비스를 제공합니다.
 2. ‘sally 서비스’는 부당한 중단, 장애 또는 지연 없이 사용자에게 서비스가 제공되도록 하며 서비스의 중단, 장애 또는 지연을 최소화하기 위한 합리적인 노력을 기울입니다.
 3. ‘sally 서비스’는 언제든지 회사의 독자적인 판단에 따라 또는 아래의 각 호에 따라 사전 경고 또는 통지 없이 서비스 변경 또는 서비스나 그 일부의 제공을 일시 중단 또는 종료할 수 있습니다.
 ① 서비스를 제공하는데 사용되는 서버, 저장 공간 등 IT 인프라의 제한이 발생한 경우
 ② 기타 회사의 사정상 필요하다고 인정되는 경우
 ③ 기타 불가항력적 사유가 있는 경우
 4. ‘sally 서비스’는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
 5. ‘sally 서비스’는 제 3항 및 4항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 사용자에게 알려야 합니다.
          </pre>
          <span class="title3">제 10 조 (서비스 이용 책임)</span>
          <pre class="contents">
1. 회원은 서비스를 이용하여 상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌이 광고, 음란 사이트 등을 통한 상업행위, 상용S/W 불법 배포, 고객사 정보 불법 유출 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 ‘sally 서비스’가 책임을 지지 않습니다.
2. 이용자는 서비스가 무상 제공됨을 감안하여 ‘sally 서비스’는 이용자에게 버전 패치를 포함하는 기술 지원의 의무가 없음을 확인합니다.
3. 이용자는 서비스가 시험적일 수 있고 ‘sally 서비스’가 수정할 수 없거나 수정 불가한 결함이 포함되어 있을 수 있으며, 무상으로 서비스 됨에 따라 일시적 접속이 불가할 수 있음을 인정합니다.
4. 이용자는 서비스를 이용 책임 이외의 목적으로 사용하여 발생할 수 있는 손해에 대해서는 ‘sally 서비스’에게 청구하지 않는 것에 대해 동의합니다.
5. ‘sally 서비스’는 서비스에 저장된 사용자 또는 제3자의 개인 정보 또는 추가 정보를 포함하는 어떠한 데이터에 대한 책임도 지지 아니하며, 그러한 데이터의 삭제 또는 삭제에 대한 책임을 지지 않습니다.
6. 이용자는 ‘sally 서비스’가 본 약관과 관련하여 사전에 알거나 알 수 있었는지와 상관없이 이용자에게 발생하거나 간접적으로 발생하는 우발적 또는 특수한 손해, 체험 중단, 체험 정보의 손실 또는 기타 경제적 이익의 손실에 대한 책임을 지지 않는 것에 동의합니다.
7. ‘sally 서비스’는 이용자에게 사전 통보 없이 자체 결정에 따라 서비스를 중단할 수 있습니다. 본 약관의 종료 또는 해지와 동시에 본 약관에서 이용자에게 주어진 모든 권한과 서비스는 지체 없이 종료되며, 사용자는 서비스의 사용을 즉시 중단해야 합니다.
          </pre>
          <span class="title3">제 11 조 (서비스 탈퇴 및 이용 제한)</span>
          <pre class="contents">
1. 이용자는 ‘sally 서비스’를 통해 회원탈퇴를 신청할 수 있습니다.
2. 이용자가 회원탈퇴를 신청한 경우‘sally 서비스’를 통해 사용 중인 서비스의 제공은 즉시 중단됩니다.
3. 회사는 다음 각 호의 어느 하나에 해당하는 경우 사전통지 없이 회원탈퇴 조치하거나 또는 서비스 이용을 중지할 수 있습니다.
① 타인의 개인정보, 아이디 또는 비밀번호를 도용한 경우
② 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
③ 회사, 다른 회원 또는 제 3자의 지적재산권을 침해하는 경우
④ 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
⑤ 사용자가 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우
⑥ 서비스 운영을 고의로 방해한 경우
⑦ 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 게시하거나 광고성 정보를 홍보하는 경우
⑧ 정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
⑨ 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법 선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우
⑩ 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
⑪ 1년 이상 서비스 이용기록(로그인 기록)이 없는 경우
⑫ 본 약관을 포함하여 기타 회사가 정한 이용 조건에 위반한 경우
⑬ 기타 회사방침 또는 외부적 요인, 기술적 요인 등 제반 상황에 의하여 서비스 이용 중지 또는 제한이 필요하다고 회사가 판단하는 경우
          </pre>
          <span class="title3">제 12 조 (저작권)</span>
          <pre class="contents">
1. ‘sally 서비스’가 제공하는 서비스와 관련된 모든 저작물의 저작권 및 기타 지식재산권은 ‘sally 서비스’에 귀속합니다.
2. 이용자는 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
3. 이용자는 서비스를 이용함으로써 얻은 정보를 ‘sally 서비스’의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 본 약관에 따른 용도 이외의 용도로 이용하거나 제3자에게 공개하거나 또는 이용하게 하여서는 안 됩니다.
          </pre>
          <span class="title2">제 3 장 손해배상 및 기타 사항</span>
          <span class="title3">제 13조 (손해배상)</span>
          <pre class="contents">
‘sally 서비스’는 서비스 이용과 관련하여 사용자에게 발생한 손해가 ‘sally 서비스’ 측의 고의적인 과실로 인한 경우가 아니면 책임을 지지 않습니다.
          </pre>
          <span class="title3">제 14 조 (면책조항)</span>
          <pre class="contents">
1. ‘sally 서비스’는 서비스를 제공할 뿐 ‘sally 서비스’에서 제공되는 정보를 참조하여 발생한 거래 내용에 관한 모든 분쟁에 대해서는 거래 당사자가 책임을 져야 합니다.
2. 이용자는 ‘sally 서비스’는 이용자의 서비스를 위해서만 제공되며, 오류가 포함되어 제공될 수 있음을 확인합니다.
3. 이용자는 ‘sally 서비스’가 어떠한 보증도 없이 제공되며, ‘sally 서비스’로부터 서비스와 관련한 어떠한 보증도 없음을 확인합니다.
4. ‘sally 서비스’는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
5. ‘sally 서비스’는 이용자의 귀책 사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
6. ‘sally 서비스’ 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
7. ‘sally 서비스’는 ‘sally 서비스’ 또는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여 책임을 지지 않습니다.
          </pre>
          <span class="title3">제 15 조 (분쟁 해결)</span>
          <pre class="contents">
1. 약관과 관련된 사항에 대하여는 대한민국 법을 준거법으로 합니다.
2. 사용자와 ‘sally 서비스’는 본 계약의 수행 중 양 당사자 간에 분쟁이 발생하는 경우에는 협의하여 해결함을 원칙으로 합니다. 그러나 해결되지 않는 경우에는, 본 계약과 관련하여 발생하는 모든 분쟁은 서울중앙지방법원이 배타적 · 전속적 1심 재판관할권을 가집니다.
          </pre>
          <pre class="contents">
서비스 이용약관 버전번호 : v1.0
서비스 이용약관 시행 일자 : 2021년 4월 26일
서비스 이용약관 공고 일자 : 2021년 4월 26일
          </pre>
        </div>
      </div>
      <div class="footer">
        <Copyright/>
      </div>
    </div>
  </div>
</template>

<script>
import Copyright from '@/components/Common/Copyright.vue';

export default {
  name: 'termsAndUse',
  components: {
    Copyright,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.terms-and-use {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .scroll {
    overflow: auto;
    .header {
      height: 52px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        height: 32px;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 180px;
      background-color: #f4f4f6;
      font-size: $font_size_36;
      font-weight: 600;
      letter-spacing: normal;
      text-align: left;
      color: #2a324b;
    }
    .detail-container {
      display: flex;
      justify-content: center;
      .detail {
        display: flex;
        flex-direction: column;
        margin-bottom: 98px;
        max-width: $max_width;
        width: 100%;
        white-space: pre-wrap;
        margin-left: $standard_margin_size;
        margin-right: $standard_margin_size;
        .title2 {
          font-size: $font_size_24;
          font-weight: 600;
          letter-spacing: normal;
          color: #2a324b;
          margin-top: 97px;
        }
        .title3 {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          font-weight: bold;
          margin-top: 19px;
        }
        .title4 {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          margin-top: 48px;
        }
        .contents {
          font-size: $font_size_18;
          letter-spacing: normal;
          color: #2a324b;
          line-height: 1.33;
          white-space: pre-wrap;
          margin: 0;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 31px;
      background-color: #f7f8fa;
      font-size: $font_size_12;
      font-weight: $font_weight_400;
      letter-spacing: normal;
      color: #acacac;
    }
  }
}
</style>
